export const languageData = {
    language: {
      tr: {
        translation: {
          changeLang: "İngilizce",
          homeHeader: "Anasayfa",
          announcementHeader: "Hakkımızda",
          resourceHeader:"İş Ortaklarımız",
          team: "Ekibimiz",
          contactHeader: "İletişim",
          announcementAndUpdates: "Hakkımızda",
          lukasSaginaw: "Vizyonumuz",
          vizyon:"Finans sektörüne yenilikçi çözümler sunarak, müşterilerimizin iş süreçlerinde sürdürülebilir başarılar elde etmelerine öncülük etmek. Teknoloji ve danışmanlık alanında fark yaratan yaklaşımlarımızla, sektörde güvenilir bir çözüm ortağı olarak lider konumda yer almak.",
          companyNewsletters:
            "Müşterilerimizin ihtiyaçlarına uygun, güvenilir ve yenilikçi yazılım çözümleri ile stratejik danışmanlık hizmetleri sunarak, onların iş süreçlerini iyileştirmek ve rekabet avantajı kazanmalarını sağlamak. Amacımız, finansal operasyonları daha verimli hale getirirken, teknoloji ile iş dünyası arasında köprü kurmak ve uzun vadeli iş birlikleri ile değer katmaktır.",
          readMore: "DEVAMINI OKU",
          creatingCulture: "Misyonumuz",
          review: "Neler yapıyoruz?",
          yourTeammates: "Ekibimiz",
          headOfMarketing: "Pazarlama Şefi",
          marketingManager: "Pazarlama Müdürü",
          brandMarketingConsultant: "Marka Pazarlama Danışmanı",
          resourceKits: "İş Ortaklarımız",
          clientList: "Müşteri<br/>Listemiz",
          marketingManagerKit: "Pazarlama Müdürünün<br/>Kiti",
          newbies: "Yeni Başlayanlar İçin:<br/> Biz Kimiz ve Ne Yapıyoruz",
          external: "Dış Bağlantılar ve Videolar",
          ondevApps: "Ondev Uygulamaları:<br />Misyon ve Vizyon",
          requestLeaves: "İzin Nasıl<br/>Talep Edilir",
          employeeResource: "Çalışan Kaynak<br />Grupları (ERG) Portalı",
          tip: "İpucu: Metni vurgulayarak ve araç çubuğundaki bağlantı<br/>sembolünü kullanarak bir bağlantı ekleyin.",
          contact: "İletişim Bilgileri",
          techSupport: "Teknik Destek",
          companyHelpdesk: "Şirket Yardım Masası",
          hrConcerns: "İK Endişeleri",
          reportIncident: "Olay Raporu",
          doing:"Alesta olarak, finans sektöründeki müşterilerimize ihtiyaçlarına özel yazılım çözümleri ve stratejik danışmanlık hizmetleri sunuyoruz. İş süreçlerini derinlemesine analiz ederek, verimlilik ve rekabet avantajı sağlayan özelleştirilmiş çözümler geliştiriyoruz. <br/> <br/>  Teknolojiyi en verimli şekilde iş süreçlerinize entegre ediyor, dijital dönüşümünüzü hızlandırıyor ve güvenilir altyapılar oluşturuyoruz. Regülasyonlara uyum, otomasyon, veri güvenliği ve operasyonel iyileştirme konularında sunduğumuz çözümlerle, işinizi daha güçlü ve sürdürülebilir hale getiriyoruz.",
        aboutUs:
          "Alesta olarak, her müşterimizin iş süreçlerini derinlemesine analiz ederek, sektörlerinde karşılaştıkları zorlukları ve fırsatları en iyi şekilde değerlendiriyoruz. Bu sayede, onların operasyonel verimliliklerini artırmak, riskleri minimize etmek ve rekabet avantajı elde etmelerini sağlamak için en uygun çözümleri geliştiriyoruz. <br/> <br/> Finans dünyasında hızla değişen regülasyonlara uyum sağlamak, teknolojik yenilikleri iş süreçlerine entegre etmek ve güvenli bir dijital altyapı oluşturmak, müşterilerimizin temel önceliklerinden. Biz de bu doğrultuda, gelişmiş teknolojik araçlar ve stratejik danışmanlık hizmetlerimizle, onların dijital dönüşüm yolculuklarını hızlandırıyoruz. <br/> <br/> Yazılım çözümlerimiz, müşteri taleplerine göre esnek ve modüler yapıda olup, veri güvenliği, otomasyon ve süreç iyileştirme gibi kritik konularda müşterilerimize tam destek sunmaktadır. Alesta ekibi olarak, sadece anlık ihtiyaçları karşılamayı değil, aynı zamanda uzun vadeli iş ortaklıkları kurarak müşterilerimizin sürekli gelişimini desteklemeyi hedefliyoruz.   ",
        },
      },
      en: {
        translation: {
          changeLang: "Turkish",
          homeHeader: "Home",
          announcementHeader: "Announcements",
          team:"Team",
          resourceHeader: "Resource",
          contactHeader: "Contact",
          announcementAndUpdates: "Announcements and Updates",
          lukasSaginaw: "Lukas Saginaw named board director",
          companyNewsletters:
            "Company newsletters are essential in building relationships with your employees, customers, or even prospective clients.",
          readMore: "READ MORE",
          creatingCulture: "Creating a culture of innovation",
          review: "A Year in Review:Our Wins and Milestones",
          yourTeammates: "Ekibimiz",
          headOfMarketing: "Head Of Marketing",
          marketingManager: "Marketing Manager",
          brandMarketingConsultant: "Brand Marketing Consultant",
          resourceKits: "Resource Kits and Documents",
          clientList: "Our Client<br/> List",
          marketingManagerKit: "The Marketing<br/>Manager's Kit",
          newbies: "For Newbies:<br/>Who We Are and<br/>What We Do",
          external: "External Links and Videos",
          ondevApps: "Ondev Apps:<br />Mission and Vision",
          requestLeaves: "How To<br />Request Leaves",
          employeeResource: "Employee Resource<br />Groups (ERG) Portal",
          tip: "Tip: Add a link by highlighting the text and using<br/>the link symbol on the toolbar.",
          contact: "Don't hesitate<br/>to contact us",
          techSupport: "Tech Support",
          companyHelpdesk: "Company Helpdesk",
          hrConcerns: "HR Concerns",
          reportIncident: "Report Incident",
        },
      },
    },
  };