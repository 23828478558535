import React, { useContext } from "react";
import intertech from "../icons/intertech.png";
import denizbank from "../icons/denizbank.png";
import denizbankag from "../icons/denizbankag.png";
import denizfactoring from "../icons/denizfactoring.png";
import yapikredi from "../icons/yapikredi.png";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTranslation, Trans } from "react-i18next";
import "../styles/Resource.css";
import { DataContext } from "../contexts/DataContext";
import { useLang } from "../contexts/LangContext.js";

const Resource = () => {
  const { t } = useTranslation();
  const { contextLang } = useLang();

  return (
    <section id="resource">
      <div className="resourceGeneral">
        <div id="emptyDivResource"></div>
        <h1 className="header-item-resource">{t("resourceKits")}</h1>
        <h5 className="header-item-small">
          Müşterilerimizin hayatını kolaylaştıracak çözümler üretiyoruz.
        </h5>

        <div className="intertech-logo">
          <img className="logo-intertech" src={intertech} alt="Intertech" />
        </div>

        <div className="image-grid-resource">
          <div className="image-item-resource">
            <img className="img-logo" src={denizbank} alt="Denizbank" />
          </div>
          <div className="image-item-resource">
            <img className="img-logo" src={denizbankag} alt="Denizbankag" />
          </div>
          <div className="image-item-resource">
            <img
              className="img-logo"
              src={denizfactoring}
              alt="Denizfactoring"
            />
          </div>
          <div className="image-item-resource">
            <img
              id="yapikredi"
              className="img-logo"
              src={yapikredi}
              alt="Yapı Kredi"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Resource;