import "../styles/Announcements .css";
import image2 from "../icons/office3.jpg";
import image3 from "../icons/image3.jpg";
import image4 from "../icons/office2.jpg";
import aboutUs from "../icons/aboutUs.jpg";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTranslation, Trans } from "react-i18next";

const Announcements = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useLocalStorage("lang");

  const changeLang = () => {
    const newLang = lang === "tr" ? "en" : "tr";
    setLang(newLang);
    i18n.changeLanguage(newLang);
  };
  return (
    <section id="announcements">
      <div className="announcements_general_container">
      <div className="emptyDivAbout"></div>
        <div className="announcements_content">
          
          <div className="text_section">
         
            <p id="headCol" className="col_head">{t("announcementAndUpdates")}</p>
            <p className="col_prg">
              <Trans i18nKey="aboutUs" components={{ br: <br /> }} />
            </p>
          </div>
          <div className="image_section">
            <img src={aboutUs} alt="Office" className="image" />
          </div>
        </div>
        <div className="announcements_container">
          <div className="col">
            <div className="icon_container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                viewBox="0 0 64 64"
              >
                <path
                  fill="#ffb923"
                  d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m6 46h-6.107V24.979Q28.545 28.108 24 29.608v-5.543q2.391-.783 5.195-2.967T33.043 16H38z"
                />
              </svg>
            </div>
            <div className="text-container">
              <p className="col_head">{t("creatingCulture")}</p>
              <p className="col_prg">{t("companyNewsletters")}</p>
            </div>
          </div>
          <div id="vision" className="col">
            <div className="icon_container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                viewBox="0 0 64 64"
              >
                <path
                  fill="#ffb923"
                  d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m10 40.326V48H22a15.4 15.4 0 0 1 1.949-6.098q1.624-2.881 6.416-7.643q3.856-3.846 4.731-5.217q1.177-1.892 1.177-3.738q0-2.044-1.025-3.143c-1.025-1.099-1.627-1.098-2.832-1.098q-1.788 0-2.843 1.152q-1.056 1.153-1.218 3.826l-5.686-.609q.507-5.041 3.188-7.238q2.681-2.193 6.702-2.194q4.405 0 6.924 2.543C42.002 21.086 42 22.348 42 24.869q0 2.152-.721 4.098q-.72 1.947-2.284 4.076q-1.037 1.412-3.737 4.064q-2.7 2.654-3.42 3.523a10.5 10.5 0 0 0-1.168 1.695z"
                />
              </svg>
            </div>
            <div className="text-container">
              <p className="col_head">{t("lukasSaginaw")}</p>
              <p className="col_prg">{t("vizyon")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Announcements;
