

import Announcements from "./Announcements ";
import Contact from "./Contact";
import Doing from "./Doing";


import External from "./External";
import Footer from "./Footer";
import Header from "./Header";
import NewNavbar from "./NewNavBar";

import Resource from "./Resource";
import Teammate from "./Teammate";


const HomePage=()=>{
    return(
        <>
         <NewNavbar/>
      
         <Announcements/>
         <hr style={{margin:40}}></hr>
         <Doing/>
         <hr style={{margin:40}}></hr>
         <Resource/>
 
         <Teammate/>
      
         <Contact/>
     
         
        </>
       
    )
}
export default HomePage;