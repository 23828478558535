import React from "react";
import s from "../icons/s.jpg";
import d from "../icons/d.jpg";
import t from "../icons/t.jpg";
import a from "../icons/a.jpg";
import "../styles/Teammate.css";
import { useLang } from "../contexts/LangContext";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";

const Teammate = () => {
  // const [contextLang,setContextLang]=useLang();
  // const { t, i18n } = useTranslation();
  // const [lang, setLang] = useLocalStorage("lang");

  // const changeLang = () => {
  //   const newLang = lang === "tr" ? "en" : "tr";
  //   setLang(newLang);
  //   setContextLang(newLang);
  //   i18n.changeLanguage(newLang);
  // };

  return (
    <section id="teammate">
      <div style={{ backgroundColor: "#ffb923", padding: "3rem" }}>
        <div id="emptyDiv"></div>
        <h1 className="header-item-teammate">Ekibimiz</h1>
        <div className="image-grid-teammate">
          <div className="image-item-teammate">
            <div className="deneme">
              <img src={s} alt="Dart" />
            </div>
            <div className="text-item">
              <p className="team-item">Semih Birincioğlu</p>
              <p className="item-team">Co-Founder</p>
              <a
                className="linkedin"
                href="https://tr.linkedin.com/in/semih-birincio%C4%9Flu-mba-22528891?original_referer=https%3A%2F%2Fwww.google.com%2F"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 128 128"
                >
                  <path
                    fill="#0076b2"
                    d="M116 3H12a8.91 8.91 0 0 0-9 8.8v104.42a8.91 8.91 0 0 0 9 8.78h104a8.93 8.93 0 0 0 9-8.81V11.77A8.93 8.93 0 0 0 116 3"
                  />
                  <path
                    fill="#fff"
                    d="M21.06 48.73h18.11V107H21.06zm9.06-29a10.5 10.5 0 1 1-10.5 10.49a10.5 10.5 0 0 1 10.5-10.49m20.41 29h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75v32H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53z"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="image-item-teammate">
            <div className="deneme">
              <img src={d} alt="Dart" />
            </div>
            <div className="text-item">
              <p className="team-item">Hüseyin Doruk Tunca</p>
              <p className="item-team">Co-Founder</p>
              <a
                className="linkedin"
                href="https://tr.linkedin.com/in/h%C3%BCseyin-doruk-tunca-7a03ba83"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 128 128"
                >
                  <path
                    fill="#0076b2"
                    d="M116 3H12a8.91 8.91 0 0 0-9 8.8v104.42a8.91 8.91 0 0 0 9 8.78h104a8.93 8.93 0 0 0 9-8.81V11.77A8.93 8.93 0 0 0 116 3"
                  />
                  <path
                    fill="#fff"
                    d="M21.06 48.73h18.11V107H21.06zm9.06-29a10.5 10.5 0 1 1-10.5 10.49a10.5 10.5 0 0 1 10.5-10.49m20.41 29h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75v32H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53z"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="image-item-teammate">
            <div className="deneme">
              <img src={a} alt="Dart" />
            </div>

            <div className="text-item">
              <p className="team-item">Alperen Akgün</p>
              <p className="item-team">Software Engineer</p>
              <a
                className="linkedin"
                href="https://tr.linkedin.com/in/alperen-akgn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 128 128"
                >
                  <path
                    fill="#0076b2"
                    d="M116 3H12a8.91 8.91 0 0 0-9 8.8v104.42a8.91 8.91 0 0 0 9 8.78h104a8.93 8.93 0 0 0 9-8.81V11.77A8.93 8.93 0 0 0 116 3"
                  />
                  <path
                    fill="#fff"
                    d="M21.06 48.73h18.11V107H21.06zm9.06-29a10.5 10.5 0 1 1-10.5 10.49a10.5 10.5 0 0 1 10.5-10.49m20.41 29h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75v32H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53z"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="image-item-teammate">
            <div className="deneme">
              <img src={t} alt="Dart" />
            </div>
            <div className="text-item">
              <p className="team-item">Süleyman Tunahan Ateş</p>
              <p className="item-team">Software Engineer</p>
              <a
                className="linkedin"
                href="https://tr.linkedin.com/in/tuna-ats?trk=public_profile_browsemap&original_referer=https%3A%2F%2Fwww.google.com%2F"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 128 128"
                >
                  <path
                    fill="#0076b2"
                    d="M116 3H12a8.91 8.91 0 0 0-9 8.8v104.42a8.91 8.91 0 0 0 9 8.78h104a8.93 8.93 0 0 0 9-8.81V11.77A8.93 8.93 0 0 0 116 3"
                  />
                  <path
                    fill="#fff"
                    d="M21.06 48.73h18.11V107H21.06zm9.06-29a10.5 10.5 0 1 1-10.5 10.49a10.5 10.5 0 0 1 10.5-10.49m20.41 29h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75v32H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Teammate;