import "../styles/Doing.css";
import doing from "../icons/doing.jpg";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTranslation, Trans } from "react-i18next";

const Doing = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useLocalStorage("lang");

  const changeLang = () => {
    const newLang = lang === "tr" ? "en" : "tr";
    setLang(newLang);
    i18n.changeLanguage(newLang);
  };
  return (
    <section id="doing">
      <div className="doing_general_container">
        <div className="doing_content">
          <div className="doing_image_section">
            <img src={doing} alt="Office" className="doing_image" />
          </div>
          <div className="doing_text_section">
            <p className="doing_col_head">{t("review")}</p>
            <p className="doing_col_prg">
              <Trans i18nKey="doing" components={{ br: <br /> }} />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Doing;